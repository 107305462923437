.ElementMenuTab {
    width: 100%;

    .ContentHeaderTab .ContainerItems {
        display: flex;
        width: 100%;
        height: 40px;
        background-color: #d9d9d9;
        color: #000000;
        border-radius: 10px;
    }

    .ContentHeaderTab .ContainerItems .ItemHeader {
        height: 100%;
        width: 50%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bolder;
    }

    .ContentHeaderTab .ContainerItems  .ItemHeaderSelect {
        background-color: var(--bs-primary);
        color: white;
        transition: 0.5s ease;
    }

    .ContentBodyTab {
        margin-top: 30px;
        transition: 0.5s ease;
    }
}