.ViewRendicionGastoForm {
    .container-form {
        padding: 10px 20px 100px;
    }

    .group-button {
        margin-top: 40px;
        justify-content: space-between;
  
        button {
          width: 47.5%;
          min-width: 140px;
        }
    }

    .ImporteTotalRendido {
        margin-left: auto;
        width: fit-content;
        font-size: 12px;
        border-radius: 10px;
        border: 1px solid #EBEBEB;
        padding: 4px 8px;
    }

    .GroupGastos {
        border: 1px solid #EBEBEB;
        padding: 10px;
        border-radius: 8px;
    }

    .TableNoWrap {
        th {
            white-space: nowrap;
        }
        td, td span {
            white-space: nowrap;
        }
    }

    .CustomEmptyList {
        h4 { font-size: 14px !important; }
    }
}

.ElementModalCostume {

    .ItemGastoSelect {
        -webkit-box-shadow: 0px 0px 3px 0px rgba(209,209,209,1);
        -moz-box-shadow: 0px 0px 3px 0px rgba(209,209,209,1);
        box-shadow: 0px 0px 3px 0px rgba(209,209,209,1);
        display: flex;
        flex-direction: column;
        padding: 10px 15px;
        border-radius: 8px;
        width: 99%;

        p { margin: 0; font-size: 12px; }
        .CalculoTiempo {
            margin-top: 6px;
        }
    }

    .ItemGastoSelected {
        background-color: #bcbcbc;
    }

    .GroupContentBodyMA {
        height: 250px;
        overflow-y: auto;
        width: 100%;

        .GroupItems {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .GroupContentBody{
        display: flex;
        gap: 15px;
        height: 400px;
        flex-direction: column;
        overflow-y: auto;
        padding-bottom: 40px;
    }

    .ButtonFixedBottom {
        position: absolute;
        width: 100%;
        left: 0;
        padding: 0 15px;
        bottom: 0;
    }

    .group-button {
        margin-top: 40px;
        justify-content: space-between;
  
        button {
          width: 47.5%;
          min-width: 140px;
        }
    }

    .ItemFlujo {
        background-color: #efefef;
        padding: 10px 25px 1px;
        border-radius: 12px;
        position: relative;

        .ItemCheck {
            position: absolute;
            left: 5px;
            top: 12px;
            width: 15px;
            height: 15px;
            background-color: white;
            border: 1px solid #efefef;
            border-radius: 50%;
            display: flex;

            i {
                margin-top: 2px;
                margin-left: 2px;
                font-size: 10px;
                color: white;
            }
        }

        .ItemSelected {
            background-color: var(--bs-primary);
        }
        
        label {
            font-weight: bold;
            padding-bottom: 5px;
        }

        ul {
            list-style: none;
            padding-left: 0.5rem;
            font-size: 12px;
        }
    }

    .ElementEmptyListCustom {
        margin-top: 140px;
        h4 {
            text-align: center;
            font-size: 15px;
        }
    }
}