.ViewComparativeDetail {

    .CardEmpty .fondoSeccion {
        background: #7c7c7c;
    }

    .CustomNotaDevuelto {
        span {
            color: red;
        }
    }

    .container-fluid {
        margin-bottom: 95px;
    }

    .ContentItemCollapse {
        line-height: normal;
        white-space: break-spaces;
        font-size: 14px;
    }

    .ItemDocumentDownload {
        background: #d9d9d9;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        height: 80px;
        padding: 4px 10px;
        width: 115px;
        text-align: center;

        span { display: flex; }
    }

    .container_group_button {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px 20px 0 0;
        background: #dfdfdf;
        button { color: white; }
    }

    .container_group_button .group_button {
        width: 275px;
        display: flex;
        justify-content: space-between;
    }

    .ElementStepperHeader {
        overflow: scroll;
    }

    .GroupBadge {
        display: flex;
        gap: 6px;
        justify-content: right;
        margin-top: 15px;
        .badgeCustom {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 25px;
            border-radius: 4px;
            padding: 0 10px;

            i {
                font-size: 14px;
                margin-left: 4px;
            }
        }
        .badgeCustom-multiproveedor {
            color: var(--bs-danger);
            border: 1px solid var(--bs-danger);
        }

        .badgeCustom-global{
            color: var(--bs-dark);
            border: 1px solid var(--bs-dark);
        }
    }

    .TableNoWrap {
        th {
            white-space: nowrap;
        }
        td, td span {
            white-space: nowrap;
        }
    }

    .CustomTableNota {
        tr td:nth-child(1) {
            width: 40%;
        }
    }

    .icon-copy-custom {
        border: none;
        background: transparent;
        i {
            font-size: 14px;
            color: var(--color-copy-1);
        }
    }

    .icon-copy-custom:focus {
        i {
            color: var(--color-copy-2)
        }
    }
}

.group-detail {
    display: flex;
    gap: 5px;
    flex-direction: column;
    margin-bottom: 13px;
    padding-bottom: 10px;
    
    label {
        color: #1B458F;
        font-weight: bold;
    }
    
    p {
        font-size: 12px;
        margin-bottom: 0;
        .font-normal {
            font-weight: normal;
            font-size: 14px;
        }
    }
    
    button {
        margin-top: auto;
    }

}

.label-custom-modal {
    font-size: 13px;
    color: #1B458F;
    font-weight: bold;
    margin-bottom: 4px;
}

.group-detail:nth-child(1) {
    border-bottom: 1px solid #c1c1c1;
}

.TableNoWrap {
    th {
        white-space: nowrap;
    }
    td, td span {
        white-space: nowrap;
    }
}