.pageNotAvailable {
    display: inline-block;
    background: #01397C center no-repeat;
    height: 100vh;
    margin: 0;
    color: white;
    width: 100%;

    h1 {
        // font: 4em Roboto;
        color: white;
        font-size: 3.5em;
        font-weight: bold;
        text-shadow: 4px 4px 4px black;
    }
    h6 {
        color: white;
        text-shadow: 2px 2px 4px black;
    }

    .fondoBlanco{
        border-radius: 100%;
        background-color: white;
        padding: 20px;
    }
}
