.ViewGastoForm {
    .container-form {
        padding: 10px 20px 100px;
    }

    .group-button {
        margin-top: 40px;
        justify-content: space-between;
  
        button {
          width: 47.5%;
          min-width: 140px;
        }
    }
}

.ElementViewDocumentsCostume img {
    object-fit: contain !important;
}