.ViewMainApprovals {
    .GroupCount {
        position: relative;
        div {
            position: absolute;
            top: -9.5px;
            right: -9.5px;
            width: 19px;
            height: 19px;
            background-color: #FF6F6F;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }
    }
    .page-content {
        margin-top: 30px;
        margin-bottom: 60px;
        padding: 1rem;
    }

    .btn-icon-first {
        min-width: 46px;
    }
}