.ElementViewDocumentsCostume {
  .main-image {
    position: relative;
    .btn-delete {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 200px;
    }

    img {
      opacity: 1;
      transition: 1s;
      min-height: 330px;
      min-width: 100%;
    }
    /*.main-image--loaded {
      opacity: 1;
    }*/
  }

  .divPaginado {
    display: flex;
    width: 100%;
  }

  .group-image {
    background: #0000008c;
    margin-top: 25px;
    width: 100%;
    border-radius: 10px;
    padding: 8px;
    display: flex;
    overflow-x: scroll;
    gap: 10px;

    img {
      margin-right: 8px;
      border: 2px solid #fff;
    }

    .img-selected {
      border: 2px solid #5dcbff;
    }
  }
  .image-empty {
    min-height: 400px;
  }

  .group-image::-webkit-scrollbar {
    display: none;
  }
  img {
    object-fit: cover;
    border-radius: 10px;
  }

  .item-pdf {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 2px solid white;

    i {
      color: white;
      font-size: 35px;;
    }
  }
}

.GroupBtnDocument {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;


  .GroupButtonsPicture {
    display: flex;
    gap: 15px;
  }

  .ElementInputFileImage {
    i {
      color: var(--bs-body-color);
    }
  }

  .custom-btn-dark {
    width: 150px;
  }
}