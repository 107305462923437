.ElementModalPDF {
    .divPaginado {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-top: 10px;
    
        p {
            margin: 0
        }
    }

    .ViewTextMarkOnDocument {
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 0);
        position: absolute;
        top: 0;
        justify-content: center;
        align-items: center;
        display: flex;

        span {
            transform: rotate(-45deg);
            font-weight: bolder;
            color: #ff000052;
            font-size: 30px;
        }
    }
}

.react-pdf__Page {
    width: 100%;
}

.react-pdf__Page__canvas {
    border: 3px solid black;
}

.react-pdf__Page__canvas {
    width: 100% !important;
    max-height: 400px !important;
}

.react-pdf__Page__textContent {
    display: none !important;
}

.react-pdf__Page__annotations {
    display: none !important;
}