.ViewTareoAprobacion {
    .container-fluid {
        margin-bottom: 95px;
    }

    .container_group_button {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px 20px 0 0;
        background: #dfdfdf;
        button { color: white; }
    }

    .container_group_button .group_button {
        width: 275px;
        display: flex;
        justify-content: space-between;
    }
}

.ModalMessageReject {
    font-size: 12px;

    .TextDescription {
        padding: 6px 10px;
        width: 100%;
    }
    
    .TextDescriptionApprove {
        background-color: #E9FFE5;
        color: #34B520;
    }

    .TextDescriptionReject {
        background-color: #FFEDED;
        color: #F13939;
    }

    .GroupItems {
        padding: 10px;
        hr {
            margin: 6px 0;
        }
    }
}