.ElementCollapseCostume {
    .fondoSeccion {
        display: flex;
        width: 100%;
        // flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #01397d;
        cursor: pointer;

        h6 {
            font-size: 12px;
        }

        h6,
        i {
            font-weight: 600;
            color: #c2c7d0;
        }

        .iconShow {
            transform: rotate(150deg);
            transition: 0.4s;
            font-size: 10px;
        }

        .iconNotShow {
            transform: rotate(90deg);
            transition: 0.4s;
            font-size: 10px;
        }
    }
}