.ViewMasterHome {
    margin: 20px 15px 0;

    .ItemModuleGeneric {
        height: 150px;
        margin: 0px;
        border-radius: 25px;
        cursor: pointer;
        background-position: center;
        background-size: cover;
    }

    .ItemModuleGeneric .content {
        background-color: #1b458f82;
        border-radius: 25px;
        color: white;
        width: 100%;
        height: 100%;
    }

    .ItemModuleHome {
        background-image: url('./../../../../assets/img/backgroundModuloAprobaciones.jpg');
    }

    .ItemModuleDocucobra {
        background-image: url('./../../../../assets/img/backgroundModuloDocucobra.webp');
    }

    .ItemModuleCajaChica {
        background-image: url('./../../../../assets/img/backgroundModuloCajaChica.jpg');
    }

    .ItemModuleTareo {
        background-image: url('./../../../../assets/img/backgroundModuloTareo.jpg');
    }

    .btn-icon-first {
        min-width: 46px;
    }
}
