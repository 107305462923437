.ViewRendicionGastoAprobacion {
    padding-bottom: 20px;

    .ElementStepperHeader {
        overflow: scroll;
    }

    .ElementMenuTab {
        margin-bottom: 20px;
    }

    .ContentItemCollapse {
        line-height: normal;
        white-space: break-spaces;
        font-size: 14px;
    }

    .GroupListGastos {
        display: flex;
        margin-top: 20px;
        padding: 20px 14px;
        flex-direction: column;
        border: 1px solid #EBEBEB;
        border-radius: 10px;
        background-color: white;

        .ModeSelectItem {
            //background-color: rgba(241, 241, 241, 0.5);
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            left: 0;
            top: 0;
            z-index: 1;
        }
        
        .ModeSelectItem-select {
            background: #e3e3e35e;
        }
    }

    .HeaderItems {
        display: flex;
        justify-content: space-between;

        button {
            height: 30px;
            display: flex;
            align-items: center;
        }
    }

    .GroupItems {
        flex-direction: column;
        max-height: 400px;
        overflow-y: scroll;
        display: flex;
        gap: 12px;
    }

    .GroupItems .ItemGasto {
        background-color: white;
        border: 1px solid #EBEBEB;
        border-radius: 10px;
        position: relative;
        padding: 8px 0 16px;
        width: 100%;
        margin: 0;
        
        .GroupText {
            line-height: normal;
        }

        .TextNumber {
            padding: 0;
            margin-bottom: 0.25rem;
        }

        .icon-eye-item {
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            width: auto;
        }
    }

    .BadgetStatus {
        position: absolute;
        width: auto;
        right: 0px;
        bottom: 8px;

        p {
            background-color: #F0F0F0;
            width: auto;
            margin: 0px;
            font-size: 12px;
            padding: 0 8px;
            border-radius: 6px;
        }
    }

    .BadgetAprobado p {
        background-color: #34B520;
        color: white;
    }

    .BadgetRechazado p {
        color: #F13939;
        background-color: transparent;
        border: 1px solid #F13939;
    }

    .container_group_button {
        left: 0;
    }
}

.ViewRendicionGastoAprobacion .ViewHistory {
    .GroupContentNote {
        border: 1px solid #D8D8D8;
        border-radius: 6px;
        padding: 10px 12px 20px;
        position: relative;

        label {
            background-color: #F3F4F3;
            position: absolute;
            top: -10px;
            padding: 0 6px;
        }

        .BtnComment {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            margin: 10px 0;
        }

        .ListNote {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .GroupNote {
                position: relative;
                padding: 4px 12px 12px;
                margin: 0 10px;
                border-left: 4px solid var(--bs-primary);
                border-radius: 10px;
                font-size: 12px;
                min-height: 80px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                -webkit-box-shadow: 0px 0px 3px 1px rgba(209,209,209,1);
                -moz-box-shadow: 0px 0px 3px 1px rgba(209,209,209,1);
                box-shadow: 0px 0px 3px 1px rgba(209,209,209,1);
                
                .CircleCheck {
                    position: absolute;
                    top: 0px;
                    left: -38px;
                    height: 25px;
                    width: 25px;
                    background-color: var(--bs-primary);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    i { font-size: 16px; }
                }

                .BottomText {
                    font-size: 10px;
                    position: absolute;
                    bottom: 0px;
                }
            }
        }
    }
}

.ViewDetailGasto {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: flex-end;

    .GroupDetail {
        height: 85%;
        width: 100%;
        background-color: white;
        border-radius: 20px 20px 0 0;
        padding: 15px 10px;

        .GroupDetailHeader {
            display: flex;
            justify-content: space-between;
            border-bottom: 3px solid #D9D9D9;
            padding: 0 5px 10px;

            .Title {
                display: flex;
                align-items: center;
                gap: 15px;

                >div {
                    height: 25px;
                    width: 25px;
                    background-color: black;
                    color: white;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    i { font-size: 14px; }
                }
            }

            p {
                font-weight: bolder;
                margin: 0;
            }

            i { font-size: 20px; }
        }

        .GroupDetailContent {
            padding: 10px;
            padding-bottom: 90px;

            .ViewDetailModal {
                display: flex;
                flex-direction: column;
                gap: 30px;

                .GroupTextGeneric {
                    border: 1px solid #D9D9D9;
                    border-radius: 6px;
                    padding: 6px;
                    position: relative;
                    display: flex;
                    gap: 6px;
                    flex-direction: column;
    
                    label {
                        position: absolute;
                        background: white;
                        top: -13px;
                        left: 15px;
                        padding: 0 8px;
                    }

                    .GroupText {
                        padding: 12px;
                        background-color: #EBEBEB;
                        border-radius: 6px;
                        line-height: normal;
                        font-size: 13px;
                    }
                }

                .TextAprobado {
                    color: #34B520;
                }
                .TextRechazado {
                    color: #F13939;
                }
                .TextRevision {
                    color: black
                }
            }

        }
    }
}

.ViewRendicionGastoAprobacion, .ViewDetailGasto {
    .container_group_button {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px 20px 0 0;
        background: #dfdfdf;
        button { color: white; }
    }
    
    .container_group_button .group_button {
        width: 275px;
        display: flex;
        justify-content: space-between;
    }
}

.ViewnModalApproval {
    font-size: 12px;

    .TextDescription {
        padding: 6px 10px;
        width: 100%;
    }
    
    .TextDescriptionApprove {
        background-color: #E9FFE5;
        color: #34B520;
    }

    .TextDescriptionReject {
        background-color: #FFEDED;
        color: #F13939;
    }

    .GroupItems {
        padding: 10px;
        hr {
            margin: 6px 0;
        }
    }
}