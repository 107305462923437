.ViewMain {
    .container-form {
        padding: 10px 20px 100px;
    }

    .ContentDetalleTareo {
        .GroupButton {
            display: flex;
            justify-content: flex-end;
            gap: 8px;
            button { height: 36px; }
        }
    }

    .GroupButtonSave {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .TableNoWrap {
        th {
            white-space: nowrap;
        }
        td, td span {
            white-space: nowrap;
        }
    }
}

.ModalFormAusencia {
    .input-date-custom {
        width: 48%;
    }
}

.CustomInputModalDetalle {
    width: 100px;
    margin: 0 10px;
    label { display: none; }
}

.CustomHeaderStepper {
    overflow: scroll;

    >div {
        padding: 0 30px;
    }
}