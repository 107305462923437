.ViewAnticipoForm {
    .container-form {
        padding: 10px 20px 100px;
    }

    .group-button {
        margin-top: 40px;
        justify-content: space-between;
  
        button {
          width: 47.5%;
          min-width: 140px;
        }
    }

    .MessageHelp {
        border: 1px solid #eaf31c;
        background-color: #fdffce;
        text-align: center;
        padding: 10px;
        border-radius: 8px;
        font-size: 0.8rem;
        margin-bottom: 10px;

        .DownloadTemplate {
            text-decoration: underline;
            font-weight: bold;
        }
    }
}