.ElementChartCostume {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    .StatsChart {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 125px;
        width: 125px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        span {
            font-size: 14px;
        }

        span:nth-child(3){
            position: absolute;
            bottom: 14px;
        }
    }
}