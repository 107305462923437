.ElementLoaderLazy {
    .loader-wrapper {
        position: fixed;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        z-index: 10000;
        background-color: #212529;
    }
}
