.ElementLoader {
  .loader-wrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.55;
    // background-color: #fff;
    z-index: 99992;
    background-color: white;
  }

  .hide {
    display: none;
  }

  .loading-image {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 60px;
    z-index: 99993;
    padding: 10px;
    border-radius: 50%;
    background: #ffffffcc;
    transform: translateX(-50%);
  }

  .loading-span {
    z-index: 99993;
    position: absolute;
    top: 50%;
    left: 20%;
    width: 60%;
    text-align: center;
    color: #01397d;
    font-weight: bold;
    -webkit-animation: flickerAnimation 3s infinite;
    -moz-animation: flickerAnimation 3s infinite;
    -o-animation: flickerAnimation 3s infinite;
    animation: flickerAnimation 3s infinite;
  }

  @keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
