.ViewSplash {
  // position: relative;
  overflow: hidden;
  font-size: 0.875em;

  color: #353c4e;
  height: 100%;
  width: 100%;

  .fondo {
    // position: relative;
    background: white;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .fondo:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    // opacity: 0.3;
    background-color: #01397d;
    // background-image: url("../../../../assets//img/backgroundLogin.jpg");
    // background-position: center top;
    // background-size: cover;

    // background-repeat: no-repeat;
    // background-position: 50% 0;
  }

  .img-fluid {
    border-radius: 100%;
    background-color: white;
    -webkit-animation: spin 2s infinite;
    -moz-animation: spin 2s infinite;
    -o-animation: spin 2s infinite;
    animation: spin 2s infinite;
    padding: 20px;
  }

  .login-block {
    position: absolute;
    left: 50%;
    top: 45%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 15px;
    z-index: 2;
  }

  .textLoader {
    // position: absolute;
    // top: 55%;
    color: white;
    font-weight: bold;
    -webkit-animation: flickerAnimation 3s infinite;
    -moz-animation: flickerAnimation 3s infinite;
    -o-animation: flickerAnimation 3s infinite;
    animation: flickerAnimation 3s infinite;
  }

  @keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
}
