.ViewMasterRecuperarPassword {
  overflow: hidden;
  font-size: 0.875em;

  color: #353c4e;
  height: 100%;
  width: 100%;

  .fondo {
    position: relative;
    background: white;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .fondo:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.3;
    background-image: url("../../../../assets//img/backgroundLogin.jpg");
    background-position: center top;
    background-size: cover;

    background-repeat: no-repeat;
    // background-position: 50% 0;
  }

  .form-check-label {
    padding-top: 2px;
    font-size: 12px;
    margin-left: 5px;
  }

  .login-block {
    position: absolute;
    left: 50%;
    top: 45%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 15px;
    z-index: 2;
  }

  .fa-solid{
    font-size: 12px;
  }

  .form-control {
    background-color: #294e7b;
    color: white;
    border-left: 0px;
    font-size: 12px !important;
  }

  .form-control:focus {
    background-color: #294e7b;
    color: white;
    border-left: 0px;
    font-size: 12px !important;
  }

  .input-group-text {
    background-color: #294e7b;
    color: white;
    border-right: 0px;
  }

  ::placeholder {
    color: white;
  }

  .form-check-input {
    border-color: #01397d !important;
    font-size: 15px;
    margin-left: -30px;
  }

  .form-check-input:checked {
    background-color: #01397d !important;
    border-color: #01397d !important;
  }
}
