.ViewMainApprovals {
    .page-content {
        margin-top: 30px;
        margin-bottom: 60px;
        padding: 1rem;
    }

    .btn-icon-first {
        min-width: 46px;
    }

    .ContainerMyAccount {
        border: 1px solid #1B458F;
        border-radius: 8px;
        position: relative;
        padding: 10px;
        margin: 10px 0 25px;

        .MessageErrorMyAccount {
            width: 100%;
            margin: 10px 0;
            background-color: black;
            color: white;
            border-radius: 6px;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }

        .LoadingMyAccount {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: #e9e9e9b3;
            border-radius: 8px;
            top: 0;
            left: 0;
            z-index: 100;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        .ReloadMyStatus {
            position: absolute;
            top: 10px;
            right: 20px;
        }

        label {
            background-color: #F3F4F3;
            position: absolute;
            padding: 0 6px;
            top: -10px;
            left: 10px;
            border-radius: 10px;
        }

        .ItemMyAccount {
            border: 1px solid black;
            align-items: center;
            border-radius: 6px;
            width: 100%;
            height: 45px;
            display: flex;

            .GroupIcon {
                width: 45px;
                background-color: black;
                height: 45px;
                border-radius: 6px 0 0 6px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .Information {
                display: flex;
                flex-direction: column;
                padding: 10px 4px 10px 10px;
                position: relative;
                width: 100%;

                span { font-size: 12px; }
                .IconArrow {
                    position: absolute;
                    transform: translateY(-50%);
                    font-size: 20px;
                    right: 10px;
                    top: 60%;
                }
                .Active {
                    top: 40%;
                }
            }
        }

        .ItemMyAccount-Open {
            border-radius: 6px 6px 0 0;

            .GroupIcon {
                border-radius: 6px 0 0 0;
            }
        }

        .ListItemMyAccount {
            background-color: white;
            border: 1px solid black;
            border-top: 0 !important;
            padding: 12px 8px;
            border-radius: 0 0 6px 6px;

            .ElementEmptyList  {
                i { font-size: 20px !important; }
                h4 { font-size: 14px !important; margin-bottom: 0; }
            }
        }
    }
}