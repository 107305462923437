.ViewGenericList{
    --color-copy-1: #838383;
    --color-copy-2: #1B458F;

    .group-btn-option {
        justify-content: flex-end;
        gap: 15px;

        .btn-seleccionar {
            height: 30px;
            align-items: center;
            text-align: center;
            display: flex;
        }
    }

    .container_group_button {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px 20px 0 0;
        background: #dfdfdf;
        button { color: white; }
    }

    .container_group_button .group_button {
        width: 275px;
        display: flex;
        justify-content: space-between;
    }

    .ModeSelectItem {
        //background-color: rgba(241, 241, 241, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        left: 0;
        top: 0;
    }

    .ModeSelectItem-select {
        background-color: rgb(82 82 82 / 31%);
    }
    
    .circle-select {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: rgb(136, 136, 136);
        position: absolute;
        top: -10px;
        right: 5px;
    }

    .ItemGenericList {
        position: relative;
        line-height: normal;
        white-space: break-spaces;
    }

    .btn-accion{
        border-radius: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .reload {
        bottom: 20px;
        right: 20px;
        background: white;
        padding: 10px;
        border-radius: 50%;
        color: white;
    }

    .data-empty {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0%);
    }

    .pending {
        background-color: #fde5e5;
    }

    .LabelImporte {
        font-size: 14px;
    }
    
    .icon-copy-custom {
        border: none;
        background: transparent;
        i {
            font-size: 14px;
            color: var(--color-copy-1);
        }
    }

    .icon-copy-custom:focus {
        i {
            color: var(--color-copy-2)
        }
    }

    .GroupBadge {
        display: flex;
        gap: 6px;
        justify-content: center;
        .badgeCustom {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            border-radius: 4px;

            i {
                font-size: 14px;
            }
        }
        .badgeCustom-multiproveedor {
            color: var(--bs-danger);
            border: 1px solid var(--bs-danger);
        }

        .badgeCustom-global{
            color: var(--bs-dark);
            border: 1px solid var(--bs-dark);
        }
    }
}