.ViewModalDetalle {
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    gap: 20px;
    flex-direction: column;

    .ItemInformation {
        line-height: normal;
        white-space: break-spaces;
        font-size: 14px;
        border: 1px solid black;
        padding: 20px 10px 10px 10px;
        border-radius: 10px;
        position: relative;

        .Titulo {
            position: absolute;
            top: -10px;
            background-color: #1B458F;
            color: white;
            padding: 4px 8px;
            border-radius: 10px;
            min-width: 100px;
            text-align: center;
        }

        >div >span:nth-child(1) {
            width: 120px;
        }
    }

    .ItemInformationTable {
        height: 150px;

        .table-responsive-xl {
            max-height: 150px;
            overflow: scroll;
        }
    }

    .CustomTable {
        max-height: 130px;
    }
}